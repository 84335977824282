<template>
  <div>
    <h2 v-html="$t('search_segmentation_relation_title')"></h2>
    <!-- Stichtag -->
    <DbmDatePicker v-model="searchCardStore.SegmentationRelation_relationDate" :dateLabel="$t('search_segmentation_relation_date')" :clearable="true" />
    <!-- Status -->
    <v-select
      id="search_segmentation_relation_status"
      v-model="searchCardStore.SegmentationRelation_status"
      :label="$t('search_segmentation_relation_status')"
      :items="statuses"
      item-title="text"
      item-value="id"
      clearable
      @keyup.enter="search"
    />
    <!-- Ablieferung durch -->
    <v-text-field
      id="search_segmentation_relation_ident_out"
      v-model="searchCardStore.SegmentationRelation_identOut"
      :label="$t('search_segmentation_relation_ident_out')"
      type="text"
      @keyup.enter="search"
    />
    <!-- Zukauf durch -->
    <v-text-field
      id="search_segmentation_relation_ident_in"
      v-model="searchCardStore.SegmentationRelation_identin"
      :label="$t('search_segmentation_relation_ident_in')"
      type="text"
      @keyup.enter="search"
    />
    <!-- Fütterung -->
    <v-select
      id="search_segmentation_relation_feeding"
      v-model="searchCardStore.SegmentationRelation_feeding"
      :label="$t('search_segmentation_relation_feeding')"
      :items="feedings"
      :item-title="this.$getLangKey()"
      item-value="name"
      clearable
      @keyup.enter="search"
    />
    <div class="link align-right" @click="expanded = !expanded">
      <span v-if="!expanded" v-html="$t('more')"></span>
      <span v-else v-html="$t('less')"></span>
    </div>
    <div v-if="expanded">
      <!-- expanded here -->
      <!-- Pos-Nr. -->
      <v-text-field
        id="search_segmentation_relation_id"
        v-model="searchCardStore.SegmentationRelation_id"
        :label="$t('search_segmentation_relation_id')"
        type="text"
        @keyup.enter="search"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { useFeedingsStore } from '@/store/enums/FeedingsStore'
import baseSearchVue from './baseSearch.vue'
import DbmDatePicker from '@/components/dbmDatePicker.vue'
import { useSearchSegmentationRelationStore } from '@/store/SearchSegmentationRelationStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchProcessingForm',
  extends: baseSearchVue,
  data() {
    return {
      expanded: false,
      statuses: [
        { id: 1, text: this.$t('search_legalEntity_active') },
        { id: 0, text: this.$t('search_legalEntity_not_active') }
      ]
    }
  },
  components: {
    DbmDatePicker: DbmDatePicker
  },
  computed: {
    feedings() {
      return useFeedingsStore().feedings
    }
  },

  beforeMount() {
    useFeedingsStore().fetchFeedings()
    this.setSearchCardStore(useSearchSegmentationRelationStore(), true)
  }
})
</script>
