import { defineStore } from 'pinia'
import { TermItemDefinition } from '@/services/term'

export const useSearchSegmentationRelationStore = defineStore('searchSegmentationRelationStore', {
  state: () => ({
    SegmentationRelation_relationDate: null as string | null,
    SegmentationRelation_status: null as number | null,
    SegmentationRelation_identOut: null as number | null,
    SegmentationRelation_identin: null as number | null,
    SegmentationRelation_feeding: null as string | null,
    SegmentationRelation_id: null as number | null,
    items: [
      { key: 'SegmentationRelation_relationDate', operation: ':' },
      { key: 'SegmentationRelation_status', operation: ':' },
      { key: 'SegmentationRelation_identOut', operation: ':' },
      { key: 'SegmentationRelation_identin', operation: ':' },
      { key: 'SegmentationRelation_feeding', operation: ':' },
      { key: 'SegmentationRelation_id', operation: ':' }
    ] as TermItemDefinition[]
  })
})
